<template>
    <div class="home-saas">
        <h2 class="home-saas-ani poppins block-title home-saas-title">{{ i18nPage.title }}</h2>
        <div class="home-saas-ani block-desc home-saas-desc">{{ i18nPage.desc }}</div>

        <div class="home-saas-ani home-saas-container">
            <div class="img">
                <div class="img-item" :class="[`item${index + 1}`, currentLang]" v-for="(item, index) in i18nPage.list" :key="index">
                    <h3 class="poppins title">{{ item.title }}</h3>
                    <div class="desc">{{ item.desc }}</div>
                </div>
            </div>

            <div class="hidden-sm-and-up home-saas-list">
                <div class="home-saas-ani home-saas-item" v-for="(item, index) in i18nPage.list" :key="index">
                    <h3 class="poppins home-saas-item-title" :class="[currentLang]">{{ item.title }}</h3>
                    <div class="home-saas-item-desc" :class="[currentLang]">{{ item.desc }}</div>
                </div>
                <div class="placeholder"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('homePage.saas');
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.home-saas-ani',
            triggerElement: '.home-saas',
        });
    },
};
</script>

<style lang="scss">
.home-saas {
    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        box-sizing: border-box;
        margin: 0 auto;
        background: url(~@assets/images/home/saas/bg.jpg) center 0 no-repeat;
        background-size: cover;

        &-container {
            width: 1080px;
            margin: 0 auto;
            position: relative;
            overflow: hidden;

            .img {
                width: 660px;
                height: 465px;
                background: url(~@assets/images/home/saas/a.png) 0 0 no-repeat;
                background-size: contain;
                margin: 45px auto 15px auto;
                transform: translate(40px, 0);
                position: relative;

                &-item {
                    width: 115px;
                    height: 115px;
                    position: absolute;
                    // border: solid 1px red;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;

                    .title {
                        font-size: 16px;
                        font-weight: 700;
                    }
                    .desc {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        position: absolute;
                        width: 250px;
                        left: 100%;
                        transform: translateX(20px);
                    }

                    &.item1 {
                        width: 105px;
                        height: 105px;
                        left: 43px;
                        top: 154px;

                        .desc {
                            transform: translateX(-370px);
                        }
                    }
                    &.item2 {
                        width: 128px;
                        height: 128px;
                        left: 367px;
                        top: 254px;
                    }
                    &.item3 {
                        left: 439px;
                        top: 7px;
                    }
                    &.zh,
                    &.tw {
                        .title {
                            font-size: 24px;
                            font-weight: 600;
                        }
                        .desc {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        min-height: 6.65rem;
        background: url(~@assets/images/home/saas/mbg.jpg) 0 0 no-repeat;
        background-size: cover;

        &-title {
            padding: 0.8rem 0.35rem 0.2rem 0.35rem;
            font-size: 28px;
            line-height: 1.1;
            font-weight: 600;
        }
        &-desc {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            padding: 0 0.35rem;
        }

        .img {
            width: 2.86rem;
            height: 2.2rem;
            background: url(~@assets/images/home/saas/ma.png) 0 0 no-repeat;
            background-size: contain;
            margin: 0 auto;
            position: relative;

            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                font-size: 15px;
                font-weight: 700;
                .desc {
                    display: none;
                }
                &.item1 {
                    width: 0.66rem;
                    height: 0.66rem;
                    left: 0.09rem;
                    top: 0.58rem;
                }
                &.item2 {
                    width: 0.8rem;
                    height: 0.8rem;
                    left: 1.53rem;
                    top: 1.31rem;
                }
                &.item3 {
                    width: 0.7rem;
                    height: 0.7rem;
                    left: 2.09rem;
                    top: 0.06rem;
                    font-size: 12px;
                    .title {
                        transform: scale(0.83333);
                    }
                }
            }
        }

        &-list {
            overflow: auto;
            display: flex;
            flex-wrap: nowrap;
            padding: 0.3rem 0 0.6rem 0;

            .placeholder {
                flex-shrink: 0;
                width: 0.35rem;
            }
        }
        &-item {
            width: 2rem;
            flex-shrink: 0;
            background: rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(64px);
            box-shadow: 2px 4px 25px rgba(148, 189, 217, 0.25);
            border-radius: 0.12rem;
            margin-left: 0.15rem;
            padding: 0.2rem;
            box-sizing: border-box;

            &:first-of-type {
                margin-left: 0.35rem;
            }
            &-title {
                font-size: 15px;
                line-height: 1.2;
                font-weight: 700;
                margin-bottom: 5px;
            }
            &-desc {
                font-size: 11px;
                font-weight: 400;
            }
        }
    }
}
</style>

<template>
    <div class="home-stories">
        <h2 class="block-title home-stories-ani poppins block-title home-stories-title">{{ i18nPage.title }}</h2>
        <div class="block-desc home-stories-ani block-desc home-stories-desc">{{ i18nPage.desc }}</div>

        <div class="home-stories-list">
            <div class="home-stories-ani home-stories-item" v-for="item in i18nPage.list" :key="item.id">
                <img class="home-stories-item-image" :src="require(`@assets/images/home/stories/m${item.id}.jpg`)" alt="" />
                <h3 class="poppins title">{{ item.title }}</h3>
                <div class="desc">{{ item.desc }}</div>
                <router-link v-if="item.linkId" class="more" :to="{ path: '/' + $route.params.lang + '/news/' + item.linkId, query: $route.query }" @click.native="onReadMore(item)">
                    {{ $t('readMore') }}
                    <span class="icon-arrow"></span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('homePage.stories');
        },
    },
    methods: {
        onReadMore(data) {
            console.log(data);
            this.$track('home_readmore_clk', {
                track_id: data.id,
            });
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.home-stories-ani',
            triggerElement: '.home-stories',
        });
    },
};
</script>

<style lang="scss">
.home-stories {
    background: #f9f9f9;
    @media only screen and (max-width: 768px) {
        .icon-arrow {
            width: 8px;
            height: 8px;
            background: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.95 10.45l4.775-4.775a.248.248 0 0 0-.002-.352L.95.55' stroke='%2300C8C8' stroke-linecap='round'/%3E%3C/svg%3E") 0 0 no-repeat;
            background-size: contain;
            margin-left: 5px;
        }
        padding: 0.6rem 0.35rem;
        &-title {
            font-size: 0.26rem;
            font-weight: 500;
            margin-bottom: 0.05rem;
        }
        &-desc {
            margin-bottom: 0.35rem;
        }

        &-item {
            width: 100%;
            box-shadow: 0px 0.04rem 0.38rem rgba(0, 0, 0, 0.07);
            border-radius: 0.12rem;
            margin-bottom: 0.35rem;
            overflow: hidden;
            text-align: center;
            padding-bottom: 0.35rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            &-image {
                display: block;
                width: 100%;
            }

            .title {
                font-size: 20px;
                line-height: 1.1;
                font-weight: 600;
                padding: 0.3rem 0.35rem 0.1rem 0.35rem;
            }
            .desc {
                font-size: 13px;
                line-height: 18px;
                font-weight: 400;
                padding: 0 0.35rem;
            }
            .more {
                margin-top: 0.1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #00C8C8;
                font-size: 13px;
            }
        }
    }
}
</style>

<template>
    <div class="home-stories">
        <h2 class="home-stories-ani poppins block-title home-stories-title">{{ i18nPage.title }}</h2>
        <div class="home-stories-ani block-desc home-stories-desc">{{ i18nPage.desc }}</div>

        <div class="home-stories-swiper-wrap">
            <swiper v-if="showSwiper" class="home-stories-ani home-stories-swiper" :options="swiperOption" ref="swiper">
                <swiper-slide v-for="item in i18nPage.list" :key="item.id">
                    <img class="home-stories-swiper-image" :src="require(`@assets/images/home/stories/${item.id}.jpg`)" alt="" />
                    <div class="home-stories-swiper-info">
                        <h3 class="poppins title">{{ item.title }}</h3>
                        <div class="desc">{{ item.desc }}</div>
                        <router-link v-if="item.linkId" class="more" :to="{ path: '/' + $route.params.lang + '/news/' + item.linkId, query: $route.query }" @click.native="onReadMore(item)">
                            {{ $t('readMore') }}
                            <span class="icon-arrow"></span>
                        </router-link>
                    </div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
            <div class="swiper-buttons">
                <span class="icon prev" @click="onSwiperClick('prev')">
                    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.318 18.703L.615 10l8.703-8.703" stroke="#000" stroke-width="1.231" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </span>
                <span class="icon next" @click="onSwiperClick('next')">
                    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.682 18.703L9.385 10 .682 1.297" stroke="#000" stroke-width="1.231" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
    components: {
        swiper,
        swiperSlide,
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('homePage.stories');
        },
    },
    watch: {
        currentLang() {
            // 重复的swiper语言没切换过来
            this.showSwiper = false;
            this.$nextTick(() => {
                this.showSwiper = true;
            });
        },
    },
    data() {
        return {
            showSwiper: true,
            swiperOption: {
                loop: true,
                // loopedSlides: 6,
                slidesPerView: 'auto',
                spaceBetween: 50,
                centeredSlides: true,
                autoplay: false,
                allowTouchMove: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
        };
    },
    methods: {
        onSwiperClick(type) {
            let swiper = this.$refs.swiper && this.$refs.swiper.swiper;
            if (!swiper) return;
            if (type === 'prev') {
                swiper.slidePrev();
            } else if (type === 'next') {
                swiper.slideNext();
            }
        },
        onReadMore(data) {
            console.log(data);
            this.$track('home_readmore_clk', {
                track_id: data.id,
            });
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.home-stories-ani',
            triggerElement: '.home-stories',
        });
    },
};
</script>

<style lang="scss">
.home-stories {
    background: #f9f9f9;
    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        .icon-arrow {
            width: 12px;
            height: 12px;
            background: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.95 10.45l4.775-4.775a.248.248 0 0 0-.002-.352L.95.55' stroke='%2300C8C8' stroke-linecap='round'/%3E%3C/svg%3E") 0 0 no-repeat;
            background-size: contain;
            margin-left: 5px;
        }
        .swiper-slide {
            width: 708px;
            height: 293px;
            background: #fff;
            box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.07);
            overflow: hidden;
            border-radius: 20px;
            display: flex;
        }
        &-swiper {
            padding-top: 40px;
            padding-bottom: 80px;
            &-wrap {
                position: relative;
            }
            &-image {
                display: block;
                height: 100%;
                margin-right: 40px;
                flex-shrink: 0;
            }
            &-info {
                padding-top: 24px;
                padding-right: 40px;
                .title {
                    font-size: 25px;
                    line-height: 30px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
                .desc {
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: 400;
                    margin-bottom: 8px;
                }
                .more {
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 400;
                    color: #00C8C8;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .swiper-buttons {
            width: 960px;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            pointer-events: none;
            box-sizing: border-box;
            z-index: 10;

            .icon {
                width: 40px;
                height: 40px;
                box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.08);
                background: #ffffff;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 180px;
                pointer-events: auto;
                cursor: pointer;
                &.prev {
                    left: 0;
                }

                &.next {
                    right: 0;
                }
            }
        }
        .swiper-button {
            &-prev,
            &-next {
                width: 330px;
                height: 100%;
                top: 0;
                position: absolute;
                outline: none;
                pointer-events: none;
                .icon {
                    width: 40px;
                    height: 40px;
                    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.08);
                    background: #ffffff;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 46%;
                    pointer-events: auto;
                }
            }
            &-prev {
                left: 0;
                background: linear-gradient(to right, rgba(249, 249, 249, 0.9), rgba(249, 249, 249, 0.1) 100%);
                .icon {
                    left: 240px;
                }
            }
            &-next {
                right: 0;
                background: linear-gradient(to left, rgba(249, 249, 249, 0.9), rgba(249, 249, 249, 0.1) 100%);
                .icon {
                    right: 240px;
                }
            }
        }
    }
}
</style>
